@font-face {
  font-family: "EuropaGroNr2SH-Ita";
  src: url("../webfonts/EuropaGroNr2SHIta/font.woff2") format("woff2"), url("../webfonts/EuropaGroNr2SHIta/font.woff") format("woff");
}
@font-face {
  font-family: "EuropaGroNr2SH-Rom";
  src: url("../webfonts/EuropaGroNr2SHRom/font.woff2") format("woff2"), url("../webfonts/EuropaGroNr2SHRom/font.woff") format("woff");
}
@font-face {
  font-family: "EuropaGroNr2SH-Ult";
  src: url("../webfonts/EuropaGroNr2SHUlt/font.woff2") format("woff2"), url("../webfonts/EuropaGroNr2SHUlt/font.woff") format("woff");
}
@font-face {
  font-family: "EuropaGroNr2SH-Bol";
  src: url("../webfonts/EuropaGroNr2SHBol/font.woff2") format("woff2"), url("../webfonts/EuropaGroNr2SHBol/font.woff") format("woff");
}
/* line 1, ../sass/common.scss */
*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* line 5, ../sass/common.scss */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

/* line 11, ../sass/common.scss */
body {
  font-family: EuropaGroNr2SH-Rom, sans-serif;
  font-weight: 400;
  font-size: 0;
  background-color: #fff;
  color: #333;
}

/* line 19, ../sass/common.scss */
#root {
  height: 100%;
}

/* line 23, ../sass/common.scss */
a {
  color: inherit;
  text-decoration: none;
}

/* line 28, ../sass/common.scss */
.close {
  position: absolute;
  width: 27px;
  height: 27px;
  top: 30px;
  right: 30px;
  cursor: pointer;
}

/* line 37, ../sass/common.scss */
h1 {
  font-family: EuropaGroNr2SH-Ult;
  font-size: 48px;
  letter-spacing: 2.4px;
  line-height: 44px;
  text-transform: uppercase;
  color: #B91D19;
  font-weight: normal;
  margin-top: 0;
}

/* line 48, ../sass/common.scss */
h2 {
  font-family: EuropaGroNr2SH-Ult;
  font-size: 32px;
  letter-spacing: 1.6px;
  line-height: 36px;
  text-transform: uppercase;
  color: #B91D19;
  font-weight: normal;
  margin: 30px 0 20px;
}

/* line 59, ../sass/common.scss */
h5 {
  font-size: 16px;
  margin: 0;
}

/* line 64, ../sass/common.scss */
p {
  font-size: 20px;
  line-height: 26px;
}
/* line 68, ../sass/common.scss */
p strong {
  font-weight: normal;
  font-family: EuropaGroNr2SH-Bol, sans-serif;
}
/* line 73, ../sass/common.scss */
p .highlight {
  color: #B91D19;
}

/* line 78, ../sass/common.scss */
.page {
  padding: 48px 20px;
  min-height: calc(100% - 160px);
}

/* line 83, ../sass/common.scss */
.page-login {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* line 88, ../sass/common.scss */
.page-login .container {
  width: 100%;
}
/* line 92, ../sass/common.scss */
.page-login form {
  position: relative;
  width: 100%;
}
/* line 96, ../sass/common.scss */
.page-login form p {
  margin-top: 0px;
  color: #B91D19;
}

/* line 103, ../sass/common.scss */
.container {
  position: relative;
  max-width: 714px;
  margin: 0 auto;
}

/* line 109, ../sass/common.scss */
.video {
  max-width: 898px;
  height: 505px;
  margin: 30px auto 10px;
  background-color: #EDEDED;
}

/* line 116, ../sass/common.scss */
input {
  font: inherit;
  color: inherit;
}
/* line 61, ../../../../../../../../../.gem/ruby/2.6.0/gems/compass-core-1.0.3/stylesheets/compass/css3/_user-interface.scss */
input:-moz-placeholder {
  color: inherit;
}
/* line 64, ../../../../../../../../../.gem/ruby/2.6.0/gems/compass-core-1.0.3/stylesheets/compass/css3/_user-interface.scss */
input::-moz-placeholder {
  color: inherit;
}
/* line 67, ../../../../../../../../../.gem/ruby/2.6.0/gems/compass-core-1.0.3/stylesheets/compass/css3/_user-interface.scss */
input:-ms-input-placeholder {
  color: inherit;
}
/* line 56, ../../../../../../../../../.gem/ruby/2.6.0/gems/compass-core-1.0.3/stylesheets/compass/css3/_user-interface.scss */
input::-webkit-input-placeholder {
  color: inherit;
}

/* line 125, ../sass/common.scss */
.MuiInput-root {
  width: 100%;
}
/* line 128, ../sass/common.scss */
.MuiInput-root .MuiNativeSelect-select:focus {
  background-color: transparent;
}
/* line 132, ../sass/common.scss */
.MuiInput-root .MuiNativeSelect-select.MuiNativeSelect-select {
  padding: 10px 14px;
  font-family: EuropaGroNr2SH-Rom, sans-serif;
  font-size: 20px;
  text-transform: none;
  background-color: #E6E6E6;
}
/* line 140, ../sass/common.scss */
.MuiInput-root .select-icon {
  position: absolute;
  right: 14px;
  width: 14px;
  min-width: 14px;
  margin-right: 4px;
  font-size: 0;
  line-height: 0;
  fill: #B91D19;
}

/* line 152, ../sass/common.scss */
.textinput {
  border: none;
  outline: none;
  background-color: #000;
  color: #fff;
  width: 100%;
  font-size: 28px;
  font-family: EuropaGroNr2SH-Bol, sans-serif;
  line-height: 48px;
  border-radius: 0;
  padding: 0 10px;
  margin: 5px 0;
  -moz-transition: background-color 0.25s;
  -o-transition: background-color 0.25s;
  -webkit-transition: background-color 0.25s;
  transition: background-color 0.25s;
}
/* line 166, ../sass/common.scss */
.textinput:focus {
  border-bottom: none;
}
@media (max-width: 720px) {
  /* line 152, ../sass/common.scss */
  .textinput {
    line-height: 40px;
    height: 40px;
    font-size: 24px;
  }
}

/* line 177, ../sass/common.scss */
button {
  border: none;
  outline: none;
  margin: 40px auto 0;
  width: 242px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #B91D19;
  color: #fff;
  font-size: 28px;
  letter-spacing: 1.4px;
  font-family: EuropaGroNr2SH-Ult;
  text-transform: uppercase;
  cursor: pointer;
  -moz-transition: color 0.25s linear;
  -o-transition: color 0.25s linear;
  -webkit-transition: color 0.25s linear;
  transition: color 0.25s linear;
}
/* line 195, ../sass/common.scss */
button:hover {
  color: #000;
}

/* line 1, ../sass/header.scss */
.header {
  height: 80px;
  background-color: #000000;
}
/* line 5, ../sass/header.scss */
.header .inner {
  height: 100%;
  max-width: 1142px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
@media (max-width: 1142px) {
  /* line 5, ../sass/header.scss */
  .header .inner {
    padding: 0 30px;
  }
}
/* line 16, ../sass/header.scss */
.header .inner .navi-item {
  font-size: 24px;
  letter-spacing: 1.2px;
  color: #fff;
  font-family: EuropaGroNr2SH-Bol, sans-serif;
  margin-right: 30px;
  text-transform: uppercase;
  -moz-transition: color 0.25s linear;
  -o-transition: color 0.25s linear;
  -webkit-transition: color 0.25s linear;
  transition: color 0.25s linear;
}
/* line 24, ../sass/header.scss */
.header .inner .navi-item.home {
  font-size: 14px !important;
  line-height: 14px !important;
  letter-spacing: 0.7px !important;
  text-align: center;
}
/* line 30, ../sass/header.scss */
.header .inner .navi-item.home span {
  color: #B91D19;
}
/* line 36, ../sass/header.scss */
.header .inner .navi-item:hover {
  color: #B91D19;
}
/* line 41, ../sass/header.scss */
.header .inner .navi-item.home:hover {
  color: #fff;
}

/* line 1, ../sass/home.scss */
.page-home {
  display: flex;
  flex-wrap: wrap;
}
/* line 5, ../sass/home.scss */
.page-home img {
  width: 20%;
  height: auto;
}
@media (max-width: 1023px) {
  /* line 5, ../sass/home.scss */
  .page-home img {
    width: 33.33333%;
  }
  /* line 12, ../sass/home.scss */
  .page-home img:nth-child(1) {
    order: -5;
  }
  /* line 16, ../sass/home.scss */
  .page-home img:nth-child(2) {
    order: -4;
  }
  /* line 20, ../sass/home.scss */
  .page-home img:nth-child(3) {
    order: -3;
  }
  /* line 24, ../sass/home.scss */
  .page-home img:nth-child(4) {
    order: -2;
  }
  /* line 28, ../sass/home.scss */
  .page-home img:nth-child(18) {
    display: none;
  }
  /* line 32, ../sass/home.scss */
  .page-home img:nth-child(19) {
    display: none;
  }
}
/* line 38, ../sass/home.scss */
.page-home .entry {
  position: relative;
  width: 40%;
  padding: 1.46413vw;
  background-color: #B91D19;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 3.51391vw;
  letter-spacing: 0.1757vw;
  color: #fff;
  font-family: "EuropaGroNr2SH-Ult";
  text-transform: uppercase;
  cursor: pointer;
  -moz-transition: color 0.25s linear;
  -o-transition: color 0.25s linear;
  -webkit-transition: color 0.25s linear;
  transition: color 0.25s linear;
}
@media (max-width: 1023px) {
  /* line 38, ../sass/home.scss */
  .page-home .entry {
    width: 66.66666%;
    padding: 5.33333vw;
    font-size: 5.86667vw;
    letter-spacing: 0.29333vw;
    order: -1;
  }
}
/* line 62, ../sass/home.scss */
.page-home .entry span {
  display: block;
}
/* line 66, ../sass/home.scss */
.page-home .entry .triangle {
  position: absolute;
  right: 1.46413vw;
  bottom: 1.46413vw;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 1.20791vw 0 1.20791vw 2.04978vw;
  border-color: transparent transparent transparent #ffffff;
  -moz-transition: border-color 0.25s linear;
  -o-transition: border-color 0.25s linear;
  -webkit-transition: border-color 0.25s linear;
  transition: border-color 0.25s linear;
}
@media (max-width: 1023px) {
  /* line 66, ../sass/home.scss */
  .page-home .entry .triangle {
    right: 5.33333vw;
    bottom: 5.33333vw;
    border-width: 2vw 0 2vw 3.2vw;
  }
}
/* line 84, ../sass/home.scss */
.page-home .entry:hover {
  color: #000;
}
/* line 87, ../sass/home.scss */
.page-home .entry:hover .triangle {
  border-color: transparent transparent transparent #000;
}

/* line 1, ../sass/bias-o-mat.scss */
.page-bias-o-mat {
  position: relative;
}
/* line 4, ../sass/bias-o-mat.scss */
.page-bias-o-mat .background-bias-o-mat {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  min-height: calc(100% - 160px);
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  opacity: .1;
}
/* line 16, ../sass/bias-o-mat.scss */
.page-bias-o-mat .background-bias-o-mat img {
  width: 20%;
  height: auto;
}
@media (max-width: 1023px) {
  /* line 16, ../sass/bias-o-mat.scss */
  .page-bias-o-mat .background-bias-o-mat img {
    width: 33.33333%;
  }
  /* line 23, ../sass/bias-o-mat.scss */
  .page-bias-o-mat .background-bias-o-mat img:nth-child(1) {
    order: -5;
  }
  /* line 27, ../sass/bias-o-mat.scss */
  .page-bias-o-mat .background-bias-o-mat img:nth-child(2) {
    order: -4;
  }
  /* line 31, ../sass/bias-o-mat.scss */
  .page-bias-o-mat .background-bias-o-mat img:nth-child(3) {
    order: -3;
  }
  /* line 35, ../sass/bias-o-mat.scss */
  .page-bias-o-mat .background-bias-o-mat img:nth-child(4) {
    order: -2;
  }
  /* line 39, ../sass/bias-o-mat.scss */
  .page-bias-o-mat .background-bias-o-mat img:nth-child(18) {
    display: none;
  }
  /* line 43, ../sass/bias-o-mat.scss */
  .page-bias-o-mat .background-bias-o-mat img:nth-child(19) {
    display: none;
  }
}
/* line 50, ../sass/bias-o-mat.scss */
.page-bias-o-mat a {
  display: table;
  background-color: #B91D19;
  font-family: EuropaGroNr2SH-Ult;
  color: #fff;
  font-size: 28px;
  letter-spacing: 1.4px;
  line-height: 56px;
  text-transform: uppercase;
  padding: 0 20px;
  margin-bottom: 40px;
  -moz-transition: color 0.25s linear;
  -o-transition: color 0.25s linear;
  -webkit-transition: color 0.25s linear;
  transition: color 0.25s linear;
}
/* line 63, ../sass/bias-o-mat.scss */
.page-bias-o-mat a:hover {
  color: #000;
}

/* line 70, ../sass/bias-o-mat.scss */
.page-test .columns {
  display: flex;
  justify-content: space-between;
}
/* line 74, ../sass/bias-o-mat.scss */
.page-test .columns .column-left {
  width: 345px;
}
/* line 77, ../sass/bias-o-mat.scss */
.page-test .columns .column-left img {
  margin-top: 30px;
  width: 100%;
  height: auto;
}
/* line 84, ../sass/bias-o-mat.scss */
.page-test .columns .column-right {
  width: 346px;
}
/* line 87, ../sass/bias-o-mat.scss */
.page-test .columns .column-right .name {
  color: #A5A5A5;
}
/* line 91, ../sass/bias-o-mat.scss */
.page-test .columns .column-right .buttons {
  margin-top: 60px;
}
/* line 95, ../sass/bias-o-mat.scss */
.page-test .columns .column-right .button {
  width: 244px;
  height: 56px;
  display: flex;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 28px;
  letter-spacing: 1.4px;
  font-family: EuropaGroNr2SH-Ult;
  text-transform: uppercase;
  cursor: pointer;
  -moz-transition: color 0.25s linear;
  -o-transition: color 0.25s linear;
  -webkit-transition: color 0.25s linear;
  transition: color 0.25s linear;
}
/* line 110, ../sass/bias-o-mat.scss */
.page-test .columns .column-right .button:hover {
  color: #000;
}
/* line 114, ../sass/bias-o-mat.scss */
.page-test .columns .column-right .button.loeschen {
  background-color: #B91D19;
}
/* line 118, ../sass/bias-o-mat.scss */
.page-test .columns .column-right .button.offline {
  background-color: #F5B131;
}
/* line 122, ../sass/bias-o-mat.scss */
.page-test .columns .column-right .button.freigeben {
  background-color: #56AF46;
}

/* line 130, ../sass/bias-o-mat.scss */
.table-wrapper {
  max-width: 1064px;
  padding: 0 20px;
  margin: 0 auto;
}
/* line 135, ../sass/bias-o-mat.scss */
.table-wrapper .table {
  margin: 20px auto 20px;
  position: relative;
}
/* line 139, ../sass/bias-o-mat.scss */
.table-wrapper .table .row {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid #000;
}
/* line 145, ../sass/bias-o-mat.scss */
.table-wrapper .table .row .cell {
  font-size: 16px;
  padding: 15px 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  align-items: center;
  font-size: 16px;
}
/* line 155, ../sass/bias-o-mat.scss */
.table-wrapper .table .row .cell.createdAt {
  width: 100px;
  padding-right: 8px;
}
/* line 160, ../sass/bias-o-mat.scss */
.table-wrapper .table .row .cell.id {
  width: 100px;
  padding-right: 8px;
}
/* line 165, ../sass/bias-o-mat.scss */
.table-wrapper .table .row .cell.firstname {
  width: 200px;
  padding-right: 8px;
}
/* line 170, ../sass/bias-o-mat.scss */
.table-wrapper .table .row .cell.lastname {
  width: 200px;
  padding-right: 8px;
}
/* line 175, ../sass/bias-o-mat.scss */
.table-wrapper .table .row .cell.status {
  margin-left: auto;
  width: 100px;
  padding-right: 8px;
}
/* line 181, ../sass/bias-o-mat.scss */
.table-wrapper .table .row .cell .freigegeben {
  color: #56AF46;
}
/* line 185, ../sass/bias-o-mat.scss */
.table-wrapper .table .row .cell .geloescht {
  color: red;
}
/* line 189, ../sass/bias-o-mat.scss */
.table-wrapper .table .row .cell .offline {
  color: #F5B131;
}
/* line 194, ../sass/bias-o-mat.scss */
.table-wrapper .table .row.header {
  position: relative;
  height: auto;
  z-index: auto;
  background-color: transparent;
}
/* line 200, ../sass/bias-o-mat.scss */
.table-wrapper .table .row.header .cell {
  font-family: EuropaGroNr2SH-Bol, sans-serif;
  font-size: 16px;
}
/* line 204, ../sass/bias-o-mat.scss */
.table-wrapper .table .row.header .cell.sortable {
  cursor: pointer;
}
/* line 208, ../sass/bias-o-mat.scss */
.table-wrapper .table .row.header .cell .triangle {
  margin-left: 6px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 4.5px 0 4.5px;
  border-color: #000 transparent transparent transparent;
}
/* line 219, ../sass/bias-o-mat.scss */
.table-wrapper .table .row.body {
  cursor: pointer;
  -moz-transition: background-color 0.25s linear;
  -o-transition: background-color 0.25s linear;
  -webkit-transition: background-color 0.25s linear;
  transition: background-color 0.25s linear;
}
/* line 223, ../sass/bias-o-mat.scss */
.table-wrapper .table .row.body.noclick {
  cursor: default;
}

/* line 231, ../sass/bias-o-mat.scss */
.pagination {
  position: relative;
  font-size: 0;
  line-height: 0;
  margin: 20px 0 80px;
}
/* line 237, ../sass/bias-o-mat.scss */
.pagination .left {
  display: inline-block;
  vertical-align: middle;
  width: 50%;
}
@media (max-width: 720px) {
  /* line 237, ../sass/bias-o-mat.scss */
  .pagination .left {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
}
/* line 248, ../sass/bias-o-mat.scss */
.pagination .left input {
  display: inline-block;
  vertical-align: middle;
  outline: none;
  -webkit-appearance: none;
  background-color: transparent;
  width: 60px;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  padding: 0 10px;
  margin: 0 10px;
  -moz-transition: background-color 0.25s;
  -o-transition: background-color 0.25s;
  -webkit-transition: background-color 0.25s;
  transition: background-color 0.25s;
}
/* line 264, ../sass/bias-o-mat.scss */
.pagination .right {
  display: inline-block;
  vertical-align: middle;
  width: 50%;
  text-align: right;
}
@media (max-width: 720px) {
  /* line 264, ../sass/bias-o-mat.scss */
  .pagination .right {
    width: 100%;
    text-align: center;
  }
}
/* line 275, ../sass/bias-o-mat.scss */
.pagination .right .label {
  margin: 0 5px;
}
/* line 280, ../sass/bias-o-mat.scss */
.pagination .label {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
}
/* line 286, ../sass/bias-o-mat.scss */
.pagination .button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  width: 20px;
  height: 23px;
  border-radius: 2px;
  text-align: center;
  margin: 0 5px;
  cursor: pointer;
  -moz-transition: background-color 0.25s;
  -o-transition: background-color 0.25s;
  -webkit-transition: background-color 0.25s;
  transition: background-color 0.25s;
}
/* line 299, ../sass/bias-o-mat.scss */
.pagination .button.disabled {
  pointer-events: none;
  opacity: .3;
}
/* line 304, ../sass/bias-o-mat.scss */
.pagination .button .arrow-double-icon {
  width: 13.16px;
  height: 11px;
}
/* line 309, ../sass/bias-o-mat.scss */
.pagination .button .arrow-icon {
  width: 6.16px;
  height: 11px;
}
/* line 314, ../sass/bias-o-mat.scss */
.pagination .button:last-child {
  margin-right: 0;
}
@media (max-width: 720px) {
  /* line 314, ../sass/bias-o-mat.scss */
  .pagination .button:last-child {
    margin-right: 5px;
  }
}
